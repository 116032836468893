import React from "react"
import SubHeroTwin from "../components/SubHeroTwin"
import { useBlogQuery } from "../hooks/useBlogQuery"

const HeroBlog = ({ data }) => {

    const data2 = useBlogQuery()
    const short = data2.allSanityBlog.edges[0].node
    
    return (
      <div className="relative">
        <SubHeroTwin
          heroImage={short.heroImage.asset.fluid}
          heroTitle={short.heroTitle}
          heroSubtitle={short.heroSubtitle}
          icon1={short.heroIcon1.asset.fluid.src}
          icon2={short.heroIcon2.asset.fluid.src}
        />
      </div>
    )
  }


export default HeroBlog
