import React from "react"
import Layout from "../components/layout"
import HeroBlog from "../components/HeroBlog"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Link from "gatsby-link"

class BlogPage extends React.Component {
  render() {
  const { data } = this.props
  const { currentPage, numPages } = this.props.pageContext
  let allPosts = data.allSanityBlogPost.edges
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <Layout>
      <SEO
        title="Blog | Orange Color Media Inc."
      />
      <HeroBlog />

      <div className="my-25">
        <div className="max-w-twelve mx-auto flex flex-wrap justify-center">
          {allPosts.map(post => (
            <div
              className="m-4 lg:w-30% md:w-45% w-5/6"
              style={{ border: "1px solid #e6e6e6" }}
              key={post.node.id}
            >
              <Link to={`/${post.node.slug.current}`}>
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <div className="w-full mb-10 ">
                      <img
                        src={post.node.featuredImage.asset.fluid.src}
                        width="100%"
                        alt={post.node.title}
                      />
                    </div>

                    <div className="px-8 font-hairline">
                      <h3 className="text-lg text-green mb-6">
                        {post.node.title}
                      </h3>
                      <p
                        className="text-sm text-grey mb-8"
                        style={{ color: "#777" }}
                      >
                        {post.node.excerpt}
                      </p>
                    </div>
                  </div>
                  <p className="text-sm py-4 px-8 text-grey font-hairline" style={{ color: "#a3a3a3", borderTop: "1px solid #e6e6e6" }}>
                    {post.node.releaseDate}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
          <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
          }}
          className="mt-12 max-w-md mx-auto min-w-md"
        >
          {!isFirst && (
            <Link to={`/blog/${prevPage}`} rel="prev">
              Previous Page
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number${i + 1}`}
              style={{
                margin: 0,
              }}
            >
              <Link
                to={`/blog/${i === 0 ? '' : i + 1}`}
                style={{
                  padding: "6px",
                  margin: "20px 10px",
                  textDecoration: 'none',
                  color: i + 1 === currentPage ? '#ffffff' : '',
                  background: i + 1 === currentPage ? '#F57B20' : '',
                }}
              >
                {i + 1}
              </Link>
            </li>
          ))}
          {!isLast && (
            <Link to={`/blog/${nextPage}`} rel="next">
              Next Page
            </Link>
          )}
        </ul>
        <div className="mx-auto flex">

        </div>
      </div>
    </Layout>
  )
}
}


export const pageQuery = graphql`
  query BlogPostssQuery($skip: Int!, $limit: Int!) {
    allSanityBlogPost(
      sort: { fields: releaseDate, order: DESC }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          title
          excerpt
          slug {
            current
          }
          releaseDate(formatString: "MMMM Do, YYYY")
          featuredImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage